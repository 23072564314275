<template>
	<div class="w-100">
		<div style="position: relative;">
			<div style="display: flex; justify-content: center; margin-bottom: 2rem; font-weight: bolder;">
				<div style="width: 50%; text-align: center;">
					<div style="font-size: 1.1rem;">{{ institution.name.toUpperCase() }}</div>
					<div style="font-size: .8rem;">{{ institution.address }}</div>
				</div>
			</div>
			
			<div style="width: 20%; padding-right: 2rem; top: 0;position: absolute;">
				<img :src="institutionLogo" style="max-width: 100%; max-height: 150px;"
				     :alt="institution.name.toUpperCase()+'\'s logo'"/>
			</div>
		</div>
		<div style="margin-bottom: 1rem;font-weight: bolder;">Family Partner Code: {{ Object.keys(partner).length===0 ? '---' : partner.code }}</div>
		<div style="margin-bottom: 1rem;font-weight: bolder;">Head of Family: <span style="text-transform: uppercase !important;">{{ familyHead.fullName }}</span></div>
		<div>
			<table style="width: 100%; border: 1px solid #999; margin-bottom: 2rem; border-collapse: collapse;">
				<thead>
					<tr style="padding: .5rem; font-weight: bolder; border: 1px solid #999;">
						<td style="padding: .5rem; border: 1px solid #999;">Full name</td>
						<td style="padding: .5rem; border: 1px solid #999;">Partner Code</td>
						<td style="padding: .5rem; border: 1px solid #999;">Relation</td>
					</tr>
				</thead>
				<tbody>
					<tr style="padding: .5rem; border: 1px solid #999; text-transform: uppercase !important;"
					    v-for="(member, index) in members"
					    :key="'member_index'+index">
						<td style="padding: .5rem; border: 1px solid #999;">{{ member.fullName }}</td>
						<td style="padding: .5rem; border: 1px solid #999;">{{ member.code }}</td>
						<td style="padding: .5rem; border: 1px solid #999;">
							{{ $root['displayFamilyRole'](member.familyRole) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		
		<div style="display: flex; flex-wrap: wrap; margin-bottom: 2rem;">
			<div style="width: 50%;">Phone: {{ familyPhoneNumber }}</div>
			<div style="width: 50%;">Email: {{ familyEmail }}</div>
			<div style="width: 100%; margin-bottom: 2rem;">
				<div style="width: 100%; border-bottom: 1px solid #ccc; padding-top: .5rem;">Address:</div>
				<div>{{ familyAddress }}</div>
				<div>{{ familyCountry }}</div>
			</div>
			<div style="width: 50%;">
				<div style="font-weight: bold;">Birthdays:</div>
				<ul v-if="birthdays.length>0">
					<li v-for="(birthday, index) in birthdays"
					    :key="'birthday_'+index">{{ getDateFromTimeStamp(birthday) }}</li>
				</ul>
				<div v-else>No birthday(s)</div>
			</div>
			<div style="width: 50%;">
				<div style="font-weight: bold;">Marriage Anniversary:</div>
				<div v-if="theFamily!==null">{{ (typeof theFamily.dob !=='undefined' && theFamily.dob!==null) ? getDateFromTimeStamp(theFamily.dob) : '---' }}</div>
			</div>
		</div>
		
		<div style="text-align: right !important;">
			Last Update: {{ lastUpdate===null ? '---' : getDateFromTimeStamp(lastUpdate) }}
		</div>
	</div>
</template>

<script>
import {FAMILY_CREATION_MODEL} from "@/dashboard/members/members";
import {getCountries} from "@/dashboard/utils/countries";
import {getDateFromTimeStamp} from "@/utils/AppFx";
import dnoteLogo from "@/assets/img/logos/favicon.png";
import {APP_CONFIG} from "@/utils/APP_CONFIG";

export default {
	name: "FamilyCard",
	data(){
		return {
		}
	},
	computed : {
		institutionLogo: function(){
			let institution = this.institution;
			if(institution===null) return dnoteLogo;
			if(institution.logo!==null) return APP_CONFIG.BACKEND_SERVER+"/files/logo/"+institution.logo;
			return dnoteLogo;
		},
		theFamily: function(){
			return this.family;
		},
		partner: function(){
			let partnerMap = {
				"HUSBAND" : "WIFE",
				"WIFE" : "HUSBAND",
				"FATHER": "MOTHER",
				"MOTHER": "FATHER"
			};
			if(Object.keys(this.familyHead).length>0){
				let partner = this.members.find(member=>{
					let opposite = partnerMap[this.familyHead.familyRole];
					return member.familyRole===opposite;
				});
				if(typeof partner!=='undefined') return partner;
			}
			return {};
		},
		members: function(){
			if(this.theFamily===null) return [];
			return (typeof this.theFamily.members!=='undefined' && this.theFamily.members!==null)
				? this.theFamily.members
				: [];
		},
		institution: function(){ return this.$root['institution'];},
		familyHead: function(){
			if(this.theFamily===null) return {};
			let familyHead = this.theFamily.members.find(member=>member.id===this.theFamily.familyHead);
			if(typeof familyHead==='undefined') return {};
			return familyHead;
		},
		familyPhoneNumber: function(){
			if(this.theFamily===null) return "---";
			return '+'+this.theFamily.phoneCode+' '+this.theFamily.phone;
		},
		familyEmail: function(){
			if(this.theFamily===null) return "---";
			return (typeof this.theFamily.email==='undefined' || this.theFamily.email===null) ? '---' : this.theFamily.email;
		},
		familyAddress: function(){
			if(this.theFamily===null) return "---";
			let address = [];
			if(typeof this.theFamily.addressLine1!=='undefined' || this.theFamily.addressLine1!=null) address.push(this.theFamily.addressLine1);
			if(typeof this.theFamily.addressLine2!=='undefined' || this.theFamily.addressLine2!=null) address.push(this.theFamily.addressLine2);
			if(typeof this.theFamily.addressLine3!=='undefined' || this.theFamily.addressLine3!=null) address.push(this.theFamily.addressLine3);
			address = address.join(", ");
			return address;
		},
		familyCountry: function(){
			if(this.theFamily===null) return "---";
			let countryAddress = [],
				country = this.getCountries().find(country=> country.value===this.theFamily.country);
			countryAddress.push(this.theFamily.state);
			if(typeof country!=='undefined') countryAddress.push(country.label);
			return countryAddress.join(', ');
		},
		birthdays: function(){
			if(this.theFamily===null) return [];
			if(typeof this.theFamily.members==='undefined') return [];
			return this.theFamily.members.map(member=>{
				return member.dob;
			}).filter(dob=>dob!==null);
		},
		lastUpdate: function(){
			if(this.theFamily===null) return null;
			if(typeof this.theFamily.lastUpdate!=='undefined' && this.theFamily.lastUpdate!==null) return this.theFamily.lastUpdate
			return null;
		}
	},
	methods: {
		getDateFromTimeStamp,
		getCountries,
	},
	props: {
		family: {
			type: [Object, null],
			required: true,
			default(){
				return {...FAMILY_CREATION_MODEL};
			}
		}
	},
}
</script>

<style scoped lang="scss">

</style>